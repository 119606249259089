import React from "react"
import { graphql, PageProps } from "gatsby"
import sanityClient from "@sanity/client"

import Page from "@app/components/Personaliser/PersonaliserPage"
import config from "../../config"

import { ShopifyResponse, ShopifyServerData } from "@root/types/custom-types/Personaliser/ShopifyResponse"
import { ImageFragment } from "@app/graphql/fragments/ImageFragment"
import { PriceFragment } from "@app/graphql/fragments/PriceFragment"
import { VariantFieldsFragment } from "@app/graphql/fragments/Personaliser/VariantFieldsFragment"
import { ProductFieldsFragment } from "@app/graphql/fragments/Personaliser/ProductFieldsFragmment"
import { CollectionFieldsFragment } from "@app/graphql/fragments/Personaliser/CollectionFieldsFragment"
import { Override } from "@root/types/custom-types/utility"

export type Props = Override<
  PageProps<GatsbyTypes.PagePersonaliserQuery, GatsbyTypes.PagePersonaliserQueryVariables>,
  { serverData: ShopifyServerData }
>

export const query = graphql`
  query PagePersonaliser {
    page: sanityPagePersonaliser {
      url
      pageTitle
      landingPageUrl: _rawLandingPageUrl(resolveReferences: { maxDepth: 2 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      images: _rawImages(resolveReferences: { maxDepth: 2 })
      reviewsTitle
      additionalSummaryTitle
      additionalPagination
      additionalSummaryStep1Label
      additionalSummaryStep1Placeholder
      additionalSummaryStep2Label
      additionalSummaryStep2Placeholder
      additionalSummaryStep4Label
      additionalSummaryStep4Placeholder
      additionalSummaryStep5Label
      additionalSummaryStep5Placeholder
      additionalSummaryTotal
      additionalSummaryTotalLine1
      additionalSummaryTotalLine2
      additionalSummaryButtonLabel
      additionalAddProduct
      additionalSelectProduct
      additionalSelectedProduct
      additionalFree
      additionalAboutProduct
      additionalEnlargeProduct
      additionalLabelSubtitle
      additionalOnSale
      additionalOptionsBack
      additionalEdit
      additionalNewLabel
      additionalRemove
      additionalNavBack
      additionalNavNext
      additionalErrorMessagePrefix
      additionalGiftBoxErrorMessage
      additionalGiftBoxErrorButtonLabel
      additionalBuildingLabelMessage
      additionalBuildingLabelWarning
    }
    step1: sanityPagePersonaliser {
      step1Title
      step1Description
      step1RequiredMessage
    }
    step2: sanityPagePersonaliser {
      step2Title
      step2Description
      step2RequiredMessage
      step2LargeLabelTag
      step2SmallLabelTag
    }
    step3: sanityPagePersonaliser {
      step3Title
      step3Description
      step3ImageButtonLabel
      step3ImageUploadTitle
      step3ImageUploadDescription
      step3ImageUploadSmallDescription
      step3ImageUploadLimit
      step3ImageUploadErrorMessage
      step3UploadButtonLabel
      step3TextButtonLabel
      step3TextTopLineLabel
      step3TextBottomLineLabel
      step3TextMessageLabel
      step3DesignButtonLabel
      step3TextColourLabel
      step3TextColours {
        hex
        rgb {
          r
          g
          b
        }
      }
      step3BackgroundColourLabel
      step3BackgroundColours {
        hex
        rgb {
          r
          g
          b
        }
      }
      step3EditLabel
      step3Confirm
      step3LoadingMessageUpload
      step3LoadingMessageCustomise
      step3RequiredMessage
      step3ImageUploadFailedErrorMessage
    }
    step4: sanityPagePersonaliser {
      step4Title
      step4Description
    }
    step5: sanityPagePersonaliser {
      step5Title
      step5Description
      step5AddOnsBoxTag
      step5NoAddOnsBoxTag
      step5RequiredMessage
    }
    labels: allSanityPersonaliserLabel {
      edges {
        node {
          title
          labelProduct {
            ...SanityProductFragment
          }
          image: _rawImage(resolveReferences: { maxDepth: 2 })
          uuid
          customiserControls
          cropperShape
          cropperAspectRatio
          categories {
            order
            title
            handle {
              current
            }
          }
        }
      }
    }
    productLabels: sanitySettingProductLabels {
      ...SanityProductLabelsFragment
    }
    messaging: sanityPagePersonaliser {
      messagingStickerProduct {
        ...SanityProductFragment
      }
      messagingStickerTitle
      messagingStickerDescription
      messagingStickerOptions
      messagingStickerDatePlaceholder
      messagingStickerRequiredMessage
      messagingGiftCardProduct {
        ...SanityProductFragment
      }
      messagingGiftCardTitle
      messagingGiftCardDescription
      messagingGiftCardOptions
      messagingGiftCardLabel
      messagingGiftCardMessagePlaceholder
      messagingGiftCardRequiredMessage
    }
    orderSummary: sanityPagePersonaliser {
      summaryTitle
      summaryFreeShippingThreshold
      summaryFreeShippingProgress
      summaryFreeShippingReceived
      summaryAddToCart
      summarySuccessTitle
      summarySuccessMessage
      summarySuccessResetLabel
      summarySuccessCartLabel
    }
    orderSummaryUpsells: sanityPagePersonaliser {
      summaryUpsellsTitle
      summaryUpsellsDescription
      summaryUpsellsProducts {
        ...SanityProductFragment
      }
      summaryUpsellsApplyButtonLabel
      summaryUpsellsButtonLabel
    }
  }
  fragment SanityProductLabelsFragment on SanitySettingProductLabels {
    matchingIcons {
      matchingTag
      svgIcon: _rawSvgIcon(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Page {...props} data={data} />
export default Component

export async function getServerData() {
  const { sanityDataset, sanityProjectId, sanityApiVersion, shopifyStorefrontToken, shopifyShopDomain, shopifyApiVersion } = config.store

  try {
    const client = sanityClient({
      dataset: sanityDataset,
      projectId: sanityProjectId,
      apiVersion: sanityApiVersion,
    })

    const collectionProductSteps = [1, 4, 5]
    const sanityResponse = (await client.fetch(`
      *[_type == "pagePersonaliser"][0] {
        ${collectionProductSteps.map(
          i => `
          step${i}Collections[]-> { "handle": shopify.handle }
        `
        )}
      }
    `)) as {
      [key: string]: any[]
    }

    const GET_STEP_COLLECTIONS = `
      query {
        ${Object.entries(sanityResponse).map(stepCollections => {
          const step = stepCollections[0].split("step")[1].substring(0, 1)
          return stepCollections[1].map(
            (c: any) => `
            step_${step}_${c.handle.replace(/-/g, "")}: collection(handle: "${c.handle}") {
              ...CollectionFields
            }
          `
          )
        })}
      }

      ${CollectionFieldsFragment}
      ${ProductFieldsFragment}
      ${VariantFieldsFragment}
      ${ImageFragment}
      ${PriceFragment}
    `

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Shopify-Storefront-Access-Token": shopifyStorefrontToken,
      },
      body: JSON.stringify({ query: GET_STEP_COLLECTIONS }),
    }
    const shopifyResponse = await fetch(`https://${shopifyShopDomain}/api/${shopifyApiVersion}/graphql.json`, requestOptions)
    const json = await shopifyResponse.json()

    if (json.errors) {
      console.error("Error fetching data:", json.errors)
      throw "Error fetching data"
    }

    type ShopifyDataAcc = {
      [key: string]: {
        [key2: string]: any
      }
    }
    const shopifyData = Object.entries((json as ShopifyResponse).data).reduce((acc: ShopifyDataAcc, [key, value]) => {
      const [step, index] = key.split("_")
      const stepIndex = `${step}${index}`
      const collectionHandle = value.handle

      if (!acc[stepIndex]) acc[stepIndex] = {}
      acc[stepIndex][collectionHandle] = value
      return acc
    }, {})

    return {
      status: 200,
      props: shopifyData,
    }
  } catch (error) {
    console.error((error as Error).message)

    return {
      status: 500,
    }
  }
}
